import { useMutation } from "react-query";
import { createProtectedAxios } from "../../lib/auth";

const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(payload) {
  const axios = createProtectedAxios();

  const userId = payload.user_id;
  const matchId = payload.match_id;

  const endpoint = `${BASE_URL}/game/getSessionMatchBets/${userId}/${matchId}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetSessionMatchBets() {
  const { mutate, isLoading, data } = useMutation(
    ["get-session-match-bets"],
    (payload) => mutationFn(payload),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
