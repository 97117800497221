import { useMutation } from "react-query";
// import { createProtectedAxios } from "../lib/auth";
import { encryptData } from "../utils/encryptData";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;


async function mutationFn(payload) {
  // const axios = createProtectedAxios();
  const endpoint = `${BASE_URL}/users/login`;

  axios.headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
  };

  let encryptedPayload = encryptData(payload);
  let payloadData = { data: encryptedPayload };

  return axios.post(endpoint, payloadData).then(({ data }) => data);
}

export default function useLogin() {
  const { mutate, isLoading, data } = useMutation(
    ["login"],
    (payload) => mutationFn(payload),
    {
      onError(error) {
        console.log(error);
      },
    }
  );

  return { mutate, isLoading, data };
}