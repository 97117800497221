import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./Routes/AppRoutes";

function App() {
  useEffect(() => {
    if (window.location.host !== "localhost:3000") {
      const handleKeyDown = (e) => {
        if (e.keyCode === 123) {
          e.preventDefault();
        }

        if (e.ctrlKey && e.shiftKey && e.key === "I") {
          e.preventDefault();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      const detectDevTool = () => {
        let allow = 100;
        const start = +new Date();
        console.clear();
        console.log("Hello");
        debugger;
        const end = +new Date();

        if (isNaN(start) || isNaN(end) || end - start > allow) {
          alert("DEVTOOLS detected. All operations will be terminated.");
          window.location.href = "/404";
        }
      };

      const addEventListeners = () => {
        window.addEventListener("resize", detectDevTool);
        window.addEventListener("mousemove", detectDevTool);
        window.addEventListener("focus", detectDevTool);
        window.addEventListener("blur", detectDevTool);
      };

      const removeEventListeners = () => {
        window.removeEventListener("resize", detectDevTool);
        window.removeEventListener("mousemove", detectDevTool);
        window.removeEventListener("focus", detectDevTool);
        window.removeEventListener("blur", detectDevTool);
      };

      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        detectDevTool();
        addEventListeners();
      } else {
        window.addEventListener("load", detectDevTool);
        addEventListeners();
      }

      const clearConsole = () => {
        console.clear();
      };

      const intervalId = setInterval(clearConsole, 3000);

      return () => {
        document.removeEventListener("contextmenu", (e) => e.preventDefault());
        document.removeEventListener("keydown", handleKeyDown);
        removeEventListeners();
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
