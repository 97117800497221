/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import useGetCasinoBets from "./hooks/Casino/useGetCasinoBets";
import { dateTimeFormatter } from "../utils/dateFormatter";
import "./UserPage.css";

export default function CasinoAllBetComplete() {
  let userId = sessionStorage.getItem("UUID");

  const { mutate: getCasinoBets } = useGetCasinoBets();

  const [data, setData] = useState([]);

  const handleGetCasinoBets = () => {
    const payload = {
      user_id: userId,
    };

    getCasinoBets(payload, {
      onSuccess: (data) => {
        setData(data.data.data);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  useEffect(() => {
    handleGetCasinoBets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <PageHeader title="CASINO BETS" />
          <div className="menu" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>

          <div className="container-fluid table-responsive p-0">
            <table className="table table-striped table-bordered text-md">
              <thead style={{ background: "rgb(59, 57, 74)" }}>
                <tr>
                  <td
                    height="35"
                    colSpan="8"
                    align="center"
                    bgcolor="#3b394a"
                    className="TeamCombo p-0"
                  >
                    <p
                      className="mb-0"
                      style={{
                        color: "#FFF",
                        fontFamily: "Verdana, Geneva, sans-serif",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Casino Bets
                    </p>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Market Id</th>
                  <th>Amount</th>
                  <th>Rate</th>
                  <th>Bet</th>
                  <th>Result</th>
                  <th>Prof/Loss</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.game_type === "teenpati"
                          ? "Live TeenPatti"
                          : "Ander Bahar"}
                      </td>
                      <td>{item.mid}</td>
                      <td>{item.amount}</td>
                      <td>{item.rate}</td>
                      <td>{item.bet}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {item.result}
                      </td>
                      <td className="fw-bold">
                        {item.result === "loss"
                          ? item.amount * item.rate * -1
                          : item.result === "pending"
                          ? "-"
                          : item.amount * item.rate}
                      </td>
                      <td>{dateTimeFormatter(item.updatedAt)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="menu mt-2" id="menu" align="center">
        <ul className="nav">
          <li className="active abc w-100">
            <Link to="/MainMenu" style={{ height: "32.1px" }}>
              BACK TO MAIN MENU
            </Link>
          </li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
