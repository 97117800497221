import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import PageHeader from "../Components/PageHeader";
import { useState, useEffect } from "react";
// import useGetUserPlayedMatchBets from "./hooks/useGetUserPlayedMatchBets";

import { dateTimeFormatter } from "../../utils/dateFormatter";
import "../UserPage.css";

export default function CasinoTeenPatiComplete() {
  let userId = sessionStorage.getItem("UUID");

  // const { mutate: getUserPlayedMatchBets } = useGetUserPlayedMatchBets();

  const [data, setData] = useState([]);

  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <PageHeader title="LEDGER" />
          <div className="menu mt-2" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>

          <div className="container-fluid table-responsive p-0">
            <table className=" table table-striped  table-hover text-uppercase">
              <thead style={{ background: "rgb(59, 57, 74)" }}>
                <tr>
                  <td
                    height="35"
                    colSpan="8"
                    align="center"
                    bgcolor="#3b394a"
                    className="TeamCombo p-0"
                  >
                    <p
                      className="mb-0"
                      style={{
                        color: "#FFF",
                        fontFamily: "Verdana, Geneva, sans-serif",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Casino Bet
                    </p>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Game
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    MArketId
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Amount
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Rate
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Bet
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Result
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Prof/Loss
                  </th>
                  <th
                    className="text-center-th"
                    style={{ width: "50px", color: "black" }}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "600px" }}>
                        {item.name} ({dateTimeFormatter(item.date)})
                      </td>
                      <td className="text-black text-bold">
                        {item.final > 0 ? item.final : 0}
                      </td>
                      <td className="text-black text-bold">
                        {item.final < 0 ? item.final : 0}
                      </td>
                      <td
                        className={`text-bold ${
                          item.balance > 0 ? "text-black" : "text-black"
                        }`}
                      >
                        {item.balance}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="menu mt-2" id="menu" align="center">
        <ul className="nav">
          <li className="active abc w-100">
            <Link to="/MainMenu" style={{ height: "32.1px" }}>
              BACK TO MAIN MENU
            </Link>
          </li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
