import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import { useState, useEffect } from "react";
// import useGetUserPlayedMatchBets from "./hooks/useGetUserPlayedMatchBets";
import useGetMyLedger from "../hooks/useGetMyLedger";
import { dateTimeFormatter } from "../utils/dateFormatter";
import "./UserPage.css";

export default function UserMyLedger() {
  let userId = sessionStorage.getItem("UUID");

  // const { mutate: getUserPlayedMatchBets } = useGetUserPlayedMatchBets();
  const { mutate: getMyLedger } = useGetMyLedger();

  const [data, setData] = useState([]);

  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    const payload = {
      user_id: userId,
      logged_acc_type: "user",
    };

    getMyLedger(payload, {
      onSuccess: (response) => {
        let ledger_data = [];
        let updatedData = [];

        response.data.forEach((element) => {
          if (!ledger_data.some((el) => el.match_id === element.match_id)) {
            ledger_data.push(element);
          } else {
            const index = ledger_data.findIndex(
              (img) => img.match_id === element.match_id
            );
            ledger_data[index].final = ledger_data[index].final + element.final;
          }
        });

        ledger_data.forEach((el) => {
          let won_by = "";

          updatedData.push({
            date: el.games.length !== 0 ? el.games[0].server : el.createdAt,
            name: el.games.length !== 0 ? el.games[0].name : "Cash Collection",
            won_by: won_by,
            final: el.final,
            balance: 0,
          });
        });

        updatedData.sort((a, b) => {
          return +new Date(b.date) - +new Date(a.date);
        });

        updatedData.reverse();

        updatedData.forEach((ele, index) => {
          if (index - 1 != -1) {
            updatedData[index].balance =
              updatedData[index - 1].balance + updatedData[index].final;
          } else {
            updatedData[0].balance = updatedData[0].final;
          }
        });

        updatedData.reverse();

        updatedData.forEach((el) => {
          el.final = roundToTwoDecimals(el.final);
          el.balance = roundToTwoDecimals(el.balance);
          el.final = el.final * -1;
          el.balance = el.balance * -1;
        });

        setData(updatedData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }, []);

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <PageHeader title="LEDGER" />
          <div className="menu mt-2" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>

          <div className="container-fluid table-responsive p-0">
            <table className="ledger table table-striped table-bordered table-hover text-uppercase">
              <thead style={{ background: "rgb(59, 57, 74)" }}>
                <tr>
                  <td
                    height="35"
                    colSpan="6"
                    align="center"
                    bgcolor="#3b394a"
                    className="TeamCombo p-0"
                  >
                    <p
                      className="mb-0"
                      style={{
                        color: "#FFF",
                        fontFamily: "Verdana, Geneva, sans-serif",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      MY LEDGER
                    </p>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th style={{ width: "600px" }}>Match Name</th>
                  <th>Won</th>
                  <th>Lost</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "600px" }}>
                        {item.name} ({dateTimeFormatter(item.date)})
                      </td>
                      <td className="text-black text-bold">
                        {item.final > 0 ? item.final : 0}
                      </td>
                      <td className="text-black text-bold">
                        {item.final < 0 ? item.final : 0}
                      </td>
                      <td
                        className={`text-bold ${
                          item.balance > 0 ? "text-black" : "text-black"
                        }`}
                      >
                        {item.balance}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="menu mt-2" id="menu" align="center">
        <ul className="nav">
          <li className="active abc w-100">
            <Link to="/MainMenu" style={{ height: "32.1px" }}>
              BACK TO MAIN MENU
            </Link>
          </li>
        </ul>
      </div>

      <Footer />
    </>
  );
}
