import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const logout = () => {
    //alert("my fun call == ");
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <>
      <ToastContainer />

      <table className="footer">
        <tbody>
          <tr>
            <td
              className=""
              style={{ backgroundColor: "#343a40", height: "56px" }}
            >
              <div className="profile_picture_name" align="center">
                <center>
                  <h3
                    style={{
                      color: "white",
                      fontSize: "16px",
                      marginTop: "16px",
                    }}
                  >
                    {" "}
                    Copy Right @ 9EXPRO{" "}
                  </h3>
                </center>
              </div>
              <div className="profile_picture"></div>
            </td>
          </tr>
          <tr>
            <td colSpan="2"></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
