/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./PageHeader.css";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileTop from "../../img/ProfileTop.png";
import LGTop from "../../img/LGTop.png";
import useGetWalletBalance from "../hooks/useGetWalletBalance";

export default function PageHeader(props) {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let userId = sessionStorage.getItem("UUID");
  let userData = JSON.parse(sessionStorage.getItem("DATA"));

  const { mutate: getWalletBalance } = useGetWalletBalance();

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const [limit, setlimit] = useState(0);
  const [data, setData] = useState([]);
  const [uid, setuid] = useState([]);
  const [username, setFname] = useState([]);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    const first_name = localStorage.getItem("first_name");
    setFname(first_name);
    setuid(uid);
    // userProfile();

    const payload = {
      acc_type: "user",
      user_id: userId,
    };

    getWalletBalance(payload, {
      onSuccess: (response) => {
        setlimit(Math.round(response.data.data.limit));
      },
      onError: (error) => {
        console.log(error);
      },
    });

    if (userData) {
      setData(userData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <table
        width="100%"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{
          backgroundColor: "#0336FF",
          height: "83px",
        }}
      >
        <tbody>
          <tr>
            <td width="70" align="center">
              <Link to="/user-profile">
                <img src={ProfileTop} alt="" />{" "}
              </Link>
            </td>
            <td
              style={{ verticalAlign: "center" }}
              className="FontTextWhite"
              align="left"
            >
              <div className="profile_picture d-flex flex-column align-items-center">
                <p
                  align="center"
                  className="mb-1"
                  style={{ textTransform: "uppercase", fontSize: "15px" }}
                >
                  {data ? data.name : ""} : {data ? data.code : ""}
                </p>
                <p align="center" className="mb-1" style={{ fontSize: "15px" }}>
                  Coins : {limit ? limit : 0}
                </p>
              </div>
            </td>
            <td
              width="55"
              align="center"
              className="FontTextWhite"
              style={{ padding: "8px" }}
            >
              {/* <button> */}
              <span style={{ cursor: "pointer" }} onClick={logout}>
                <img src={LGTop} alt="" width="40" height="40" />

                <span>Logout</span>
              </span>
              {/* </button> */}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="welcomeMarquee">
        <marquee>
          प्रिय ग्राहक एडवांस सेशन और टॉस का रेट चालू है धन्यवाद
        </marquee>
      </div>
    </>
  );
}
