import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import ander from "../assets/img/Casino/ander.png";
import teen from "../assets/img/Casino/teen.png";

export default function UserCasino() {
  return (
    <>
      <div className="position-relative userTheme bg-light">
        <PageHeader title="LEDGER" />
        <div className="menu" id="menu" align="center">
          <ul className="nav">
            <li className="active abc w-100">
              <Link to="/MainMenu" style={{ height: "32.1px" }}>
                BACK TO MAIN MENU
              </Link>
            </li>
          </ul>
        </div>
        <table
          width="100%"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ backgroundColor: "#FFF" }}
        >
          <tbody>
            <tr>
              <td style={{ marginBottom: "100px" }}>
                <table width="100%" border="0" cellSpacing="0" cellPadding="10">
                  <tbody>
                    <tr>
                      <td align="center">&nbsp;</td>
                    </tr>
                    <tr>
                      <td align="center">
                        <Link to="/casino-bet-page-teen-patti">
                          <img src={teen} alt="" width="112" height="112" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td height="25" align="center" className="GameList">
                        <Link
                          to="/casino-bet-page-teen-patti"
                          style={{ color: "#000000" }}
                        >
                          Teen Patti
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td align="center">&nbsp;</td>
                    </tr>
                    <tr>
                      <td align="center">
                        <Link to="/casino-bet-page-ander-bhar">
                          <img src={ander} alt="" width="112" height="112" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td height="25" align="center" className="GameList">
                        <Link
                          to="/casino-bet-page-ander-bhar"
                          style={{ color: "#000000" }}
                        >
                          Ander Bahar
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>

            {/*<tr>
                  <td>
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td align="center">
                            <Link to="/user-casino-bet-table">
                              <img
                                src={""}
                                alt=""
                                width="112"
                                height="112"
                                style={{ borderRadius: "50%" }}
                              />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td height="25" align="center" className="GameList">
                            <Link
                              to="/user-casino-bet-table"
                              style={{ color: "#000000" }}
                            >
                              TeenPatti ODI
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td align="center">
                            <Link to="/user-casino-bet-table">
                              <img
                                src={dt20}
                                alt=""
                                width="112"
                                height="112"
                                style={{ borderRadius: "50%" }}
                              />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td height="25" align="center" className="GameList">
                            <Link
                              to="/user-casino-bet-table"
                              style={{ color: "#000000" }}
                            >
                              Dragon Tiger
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>*/}
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr></tr>
                    <tr></tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
      </div>

      <Footer />
    </>
  );
}
