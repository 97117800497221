/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./UserBet.css";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader1 from "./Components/PageHeader1";
import { toast } from "react-toastify";
import useSocketBetguru from "./hooks/Sockets/useSocketBetguru";
import useSocketIceexch from "./hooks/Sockets/useSocketIceexch";
import useGetSessionTotal from "./hooks/useGetSessionTotal";
import useGetBetting from "./hooks/useGetBetting";
import useGetSessionMatchBets from "./hooks/useGetSessionMatchBets";
import useGetMatchSetting from "../hooks/Games/Inplay/useGetMatchSetting";
import useGetSessionSetting from "../hooks/Games/Inplay/useGetSessionSetting";
import useCreateBet from "./hooks/useCreateBet";
import useCreateSessionBet from "./hooks/useCreateSessionBet";
import useGetWalletBalance from "./hooks/useGetWalletBalance";
import { match4, match as matchDummy } from "../cms/gameData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelevision } from "@fortawesome/free-solid-svg-icons";

export default function UserBet() {
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);

  const matchId = UrlSearchParams.get("id");
  let userId = sessionStorage.getItem("UUID");
  let code = sessionStorage.getItem("code_");
  let match_type = sessionStorage.getItem("match_type");
  let tvcode = sessionStorage.getItem("tvcode");
  let tvcodeapi = sessionStorage.getItem("tvcodeapi");
  let team1 = sessionStorage.getItem("team1");
  let team2 = sessionStorage.getItem("team2");
  let team3 = "DRAW";
  let api = sessionStorage.getItem("CALLED");
  let score_code = sessionStorage.getItem("score_code");
  let session_automatic = sessionStorage.getItem("session_automatic");

  const { mutate: getSessionTotal } = useGetSessionTotal();
  const { mutate: getBetting } = useGetBetting();
  const { mutate: getSessionMatchBets } = useGetSessionMatchBets();
  const { mutate: getMatchSetting } = useGetMatchSetting();
  const { mutate: getSessionSetting } = useGetSessionSetting();
  const { mutate: createBet } = useCreateBet();
  const { mutate: createSessionBet } = useCreateSessionBet();
  const { mutate: getWalletBalance } = useGetWalletBalance();

  const [scoreBoardType, setScoreBoardType] = useState("");

  const [showText, setShowText] = useState(false);
  const [sessionProfileLosses, setSessionProfileLosses] = useState(0);
  const [teamBets, setTeamBets] = useState([]);
  const [sessionBets, setSessionBets] = useState([]);
  const [matchSetting, setMatchSetting] = useState(null);
  const [gameSettings, setGameSettings] = useState({});
  const [isGameLocked, setIsGameLocked] = useState(false);
  const [matchComment, setMatchComment] = useState("");
  const [gameSessionSetting, setGameSessionSetting] = useState([]);
  const [matchSessionSetting, setMatchSessionSetting] = useState(null);
  const [team1Position, setTeam1Position] = useState(0);
  const [team2Position, setTeam2Position] = useState(0);
  const [team3Position, setTeam3Position] = useState(0);
  const [team1Yes, setTeam1Yes] = useState("");
  const [team1No, setTeam1No] = useState("");
  const [team2Yes, setTeam2Yes] = useState("");
  const [team2No, setTeam2No] = useState("");
  const [team3Yes, setTeam3Yes] = useState("");
  const [team3No, setTeam3No] = useState("");
  let fancyOdds = [];
  const [fancyOddsData, setFancyOddsData] = useState([]);

  const [teamName1, setTeamName1] = useState("");
  const [teamName2, setTeamName2] = useState("");
  const [tvCodeUrl, setTvCodeUrl] = useState("0");

  const [appleExchData, setAppleExchData] = useState([]);
  const [iceExchData, setIceExchData] = useState([]);
  const [betGuruData, setBetGuruData] = useState([]);

  const [betAmount, setBetAmount] = useState(0);
  const [matchBetPayload, setMatchBetPayload] = useState({});
  const [timeLeft, setTimeLeft] = useState("");
  const intervalIdRef = useRef(null);
  const [betAllowed, setBetAllowed] = useState(false);
  const [sessionBetPayload, setSessionBetPayload] = useState({});
  const [betType, setBetType] = useState("");
  const [placeBetLoader, setPlaceBetLoader] = useState(false);

  const [matchId1, setMatchId1] = useState(0);
  const [matchId2, setMatchId2] = useState(0);

  const betguruData = useSocketBetguru(matchId1);
  const iceexchData = useSocketIceexch(matchId2);

  function getTeamMatchBets() {
    const payload = {
      user_id: userId,
      match_id: matchId,
    };

    let team1_position = 0;
    let team2_position = 0;
    let team3_position = 0;

    getBetting(payload, {
      onSuccess: (data) => {
        data.data.forEach((el, index) => {
          team1_position =
            team1_position + el.teamA_bet_amount + el.teamA_lay_amount;
          team2_position =
            team2_position + el.teamB_bet_amount + el.teamB_lay_amount;
          team3_position =
            team3_position + el.draw_bet_amount + el.draw_lay_amount;

          if (el.type === "back") {
            data.data[index].mode = "L";
            if (el.betTeam === "A") {
              data.data[index].team = team1;
              data.data[index].rate = el.teamA_back_rate;
            } else {
              data.data[index].team = team2;
              data.data[index].rate = el.teamB_back_rate;
            }
          } else {
            data.data[index].mode = "K";
            if (el.betTeam === "A") {
              data.data[index].team = team1;
              data.data[index].rate = el.teamA_lay_rate;
            } else {
              data.data[index].team = team2;
              data.data[index].rate = el.teamB_lay_rate;
            }
          }
        });

        setTeam1Position(team1_position);
        setTeam2Position(team2_position);
        setTeam3Position(team3_position);
        setTeamBets(data.data);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  const handleGetSessionMatchBets = () => {
    const payload = {
      user_id: userId,
      match_id: matchId,
    };

    getSessionMatchBets(payload, {
      onSuccess: (data) => {
        setSessionBets(data.data.data);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const handleGetMatchSetting = () => {
    const gameId = matchId;

    getMatchSetting(gameId, {
      onSuccess: (data) => {
        setGameSettings(data.data.data);
        setIsGameLocked(data.data.data.isLocked);

        setMatchComment(data.data.data.pannel_message);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const handleGetSessionSetting = (gameId) => {
    getSessionSetting(gameId, {
      onSuccess: (data) => {
        setGameSessionSetting(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const getGameDetails = (data) => {
    let team_1_yes = 0;
    let team_2_yes = 0;

    if (match_type !== "Test" && data.length > 0) {
      setTeamName1(data[0].markets[0].runners[0].runnerName);
      setTeamName2(data[0].markets[0].runners[1].runnerName);
      if (gameSettings.match_bet_lock) {
        setTeam1Yes("0");
        setTeam2Yes("0");
        setTeam3Yes("0");
        setTeam1No("0");
        setTeam2No("0");
        setTeam3No("0");
      } else {
        setTeam1Yes(
          data[0].markets[0].runners[0].backOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].backOdds + ""
        );

        team_1_yes =
          data[0].markets[0].runners[0].backOdds > 99 ||
          data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].backOdds + "";

        setTeam2Yes(
          data[0].markets[0].runners[1].backOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].backOdds + ""
        );

        team_2_yes =
          data[0].markets[0].runners[1].backOdds > 99 ||
          data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].backOdds + "";

        let rate_meter =
          gameSettings === null || gameSettings === undefined
            ? 0
            : gameSettings.rate_meter;

        if (
          rate_meter === 0 ||
          rate_meter === undefined ||
          rate_meter === null
        ) {
          setTeam1No(
            data[0].markets[0].runners[0].layOdds > 99 ||
              data[0].markets[0].runners[0].rnrBallRunning
              ? "0"
              : data[0].markets[0].runners[0].layOdds + ""
          );
          setTeam2No(
            data[0].markets[0].runners[1].layOdds > 99 ||
              data[0].markets[0].runners[1].rnrBallRunning
              ? "0"
              : data[0].markets[0].runners[1].layOdds + ""
          );
        } else {
          setTeam1No(
            team_1_yes === "0"
              ? "0"
              : (Number(team_1_yes) / 100 + rate_meter) * 100 + ""
          );
          setTeam2No(
            team_2_yes === "0" ? "0" : Number(team_2_yes) / 100 + rate_meter
          );
        }
      }
    }

    // if (match_type === "Test") {
    //   setTeam3Yes(
    //     data[0].markets[0].runners[2].backOdds > 99
    //       ? "0"
    //       : data[0].markets[0].runners[2].backOdds
    //   );
    //   setTeam3No(
    //     data[0].markets[0].runners[2].layOdds > 99
    //       ? "0"
    //       : data[0].markets[0].runners[2].layOdds
    //   );
    // }

    fancyOdds = [];
    let fancyOddsIndex = data.length < 3 ? 1 : 2;

    if (match_type !== "Test") {
      if (session_automatic === "true") {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });
        }

        gameSessionSetting.forEach((ele) => {
          if (
            fancyOdds.some(
              (elm) => elm.marketType == ele.session_name && ele.allow
            )
          ) {
            const index = fancyOdds.findIndex(
              (elen) => elen.marketType === ele.session_name
            );
            fancyOdds[index].runners[0].rnrBallRunning = true;
          }
        });
      } else {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    } else {
      if (session_automatic === "true") {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });

          gameSessionSetting.forEach((ele) => {
            if (
              fancyOdds.some(
                (elm) => elm.marketType === ele.session_name && ele.allow
              )
            ) {
              const index = fancyOdds.findIndex(
                (elen) => elen.marketType === ele.session_name
              );
              fancyOdds[index].runners[0].rnrBallRunning = true;
            }
          });
        }
      } else {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    }
    setFancyOddsData(fancyOdds);
  };

  const getGameDetails3 = (data) => {
    let team_1_yes = 0;
    let team_2_yes = 0;
    let team_3_yes = 0;

    if (gameSettings.match_bet_lock) {
      setTeam1Yes("0");
      setTeam2Yes("0");
      setTeam3Yes("0");
      setTeam1No("0");
      setTeam1No("0");
      setTeam3No("0");
    } else {
      setTeam1Yes(
        data.market[0].events[0].BackPrice1 == "0.00" ||
          data.market[0].events[0].BackPrice1 == "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[0].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[0].BackPrice1.split(".")[1])
      );

      team_1_yes =
        data.market[0].events[0].BackPrice1 == "0.00" ||
        data.market[0].events[0].BackPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[0].BackPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[0].BackPrice1.split(".")[1]);

      setTeam2Yes(
        data.market[0].events[1].BackPrice1 == "0.00" ||
          data.market[0].events[1].BackPrice1 == "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[1].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[1].BackPrice1.split(".")[1])
      );

      team_2_yes =
        data.market[0].events[1].BackPrice1 == "0.00" ||
        data.market[0].events[1].BackPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[1].BackPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[1].BackPrice1.split(".")[1]);

      if (match_type === "Test") {
        setTeam3Yes(
          data.market[0].events[2].BackPrice1 == "0.00" ||
            data.market[0].events[2].BackPrice1 == "0.0"
            ? "0"
            : "0." +
                (data.market[0].events[2].BackPrice1.split(".").length == 1
                  ? "0"
                  : data.market[0].events[2].BackPrice1.split(".")[1])
        );

        team_3_yes =
          data.market[0].events[2].BackPrice1 == "0.00" ||
          data.market[0].events[2].BackPrice1 == "0.0"
            ? "0"
            : "0." +
              (data.market[0].events[2].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[2].BackPrice1.split(".")[1]);
      }

      let rate_meter =
        gameSettings === null || gameSettings === undefined
          ? 0
          : gameSettings.rate_meter;

      if (rate_meter === 0 && rate_meter === undefined) {
        setTeam1No(team_1_yes + rate_meter);
        setTeam2No(team_2_yes + rate_meter);
        if (match_type === "Test") {
          setTeam3No(team_3_yes + rate_meter);
        }
      } else {
        setTeam1No(
          data.market[0].events[0].LayPrice1 == "0.00" ||
            data.market[0].events[0].LayPrice1 == "0.0"
            ? "0"
            : "0." +
                (data.market[0].events[0].LayPrice1.split(".").length == 1
                  ? "0"
                  : data.market[0].events[0].LayPrice1.split(".")[1])
        );

        setTeam2No(
          data.market[0].events[1].LayPrice1 == "0.00" ||
            data.market[0].events[1].LayPrice1 == "0.0"
            ? "0"
            : "0." +
                (data.market[0].events[1].LayPrice1.split(".").length == 1
                  ? "0"
                  : data.market[0].events[1].LayPrice1.split(".")[1])
        );

        if (match_type === "Test") {
          setTeam3No(
            data.market[0].events[2].LayPrice1 == "0.00" ||
              data.market[0].events[2].LayPrice1 == "0.0"
              ? "0"
              : "0." +
                  (data.market[0].events[2].LayPrice1.split(".").length == 1
                    ? "0"
                    : data.market[0].events[2].LayPrice1.split(".")[1])
          );
        }
      }
    }

    fancyOdds = [];

    if (data.session == null) {
      return;
    }

    if (session_automatic === "true") {
      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) == 0,
          },
        ];
        fancyOdds.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      gameSessionSetting.forEach((ele) => {
        if (
          fancyOdds.some(
            (elm) => elm.marketType == ele.session_name && ele.allow
          )
        ) {
          const index = fancyOdds.findIndex(
            (elen) => elen.marketType == ele.session_name
          );
          fancyOdds[index].runners[0].rnrBallRunning = true;
        }
      });
    } else {
      fancyOdds = [];
      let allSessions = [];

      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) == 0,
          },
        ];

        allSessions.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      allSessions.forEach((el) => {
        if (el.runners !== undefined) {
          if (
            gameSessionSetting.some(
              (elem) =>
                elem.session_name == el.marketType && elem.allow === true
            )
          ) {
            fancyOdds.push(el);
          }
        }
      });
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails2 = (data) => {
    setBetGuruData(data);
    if (Object.keys(data).length > 0 && Object.keys(data).length !== 4) {
      let match = data.matchOdds;
      let session = data.sessionOdds;
      let score = data.score;

      if (match.length > 0) {
        setTeam1Yes(match[0].krate);
        setTeam1No(match[0].lrate);
        setTeam2Yes(match[1].krate);
        setTeam2No(match[1].lrate);

        if (match_type === "Test") {
          setTeam3Yes(match[2].krate);
          setTeam3No(match[2].lrate);
        }
      }

      setTeamName1(score?.team1);
      setTeamName2(score?.team2);

      let fancyOdds = [];

      if (session.length > 0) {
        session.forEach((el) => {
          let newSession = {
            id: el.id,
            marketType: el.name,
            runners: [
              {
                backRuns: el.yrun,
                backOdds: el.yrate * 100,
                layRuns: el.nrun,
                layOdds: el.nrate * 100,
                rnrBallRunning: el.yrate === 0 ? true : false,
                id: el.id,
              },
            ],
          };

          fancyOdds.push(newSession);
        });
      }

      setFancyOddsData(fancyOdds);
    }
  };

  const handleGetGameDetailsPaid = (gameCode) => {
    setMatchId2(gameCode);
  };

  const handleGetAppleExch = (gameCode) => {
    setMatchId1(gameCode);
  };

  const betConverter = (value) => {
    if (api === "2") {
      return value !== "0" && value !== "0.0" && value !== "0.00"
        ? parseFloat(value).toFixed(2)
        : "0";
    } else {
      return value === "0" || value === ""
        ? "0.00"
        : (Number(value) / 100).toFixed(2);
    }
  };

  const handleScroll = (e) => {
    e.preventDefault();
    const targetElement = document.querySelector("#PlaceBet");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });

      const inputElement = document.querySelector("#MatchAmountInput");
      if (inputElement) {
        inputElement.focus();
        inputElement.value = "";
      }
    }
  };

  const placeMatchBet = (payload) => {
    createBet(payload, {
      onSuccess: (data) => {
        toast.success("Bet placed successfully!");
        setMatchBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }

        getTeamMatchBets();
        handleGetSessionMatchBets();
        setPlaceBetLoader(false);
      },
      onError: (error) => {
        console.log("error", error);
        setMatchBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }
        setPlaceBetLoader(false);
        toast.error("Amount should be less then your wallet balance.");
      },
    });
  };

  const checkConditions = (matchMin, matchMax, payload) => {
    if (payload.amount < matchMin) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`);
      return;
    }

    if (payload.amount > matchMax) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`);
      return;
    }

    let team_1_yes, team_2_yes, team_1_no, team_2_no;

    if (code === "0") {
      if (matchDummy.data.length === 5) {
        team_1_yes =
          matchDummy.data[0].markets[0].runners[0].backOdds > 99 ||
          matchDummy.data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[0].backOdds / 100 + "";
        team_2_yes =
          matchDummy.data[0].markets[0].runners[1].backOdds > 99 ||
          matchDummy.data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[1].backOdds / 100 + "";
        team_1_no =
          matchDummy.data[0].markets[0].runners[0].layOdds > 99 ||
          matchDummy.data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[0].layOdds / 100 + "";
        team_2_no =
          matchDummy.data[0].markets[0].runners[1].layOdds > 99 ||
          matchDummy.data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[1].layOdds / 100 + "";

        if (
          payload.teamA_back_rate == team_1_yes &&
          payload.teamA_lay_rate == team_1_no &&
          payload.teamB_back_rate == team_2_yes &&
          payload.teamB_lay_rate == team_2_no
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.");
        }
      }
    } else {
      let data = iceExchData;

      if (data.data.length !== 0) {
        team_1_yes =
          data.data[0].markets[0].runners[0].backOdds > 99 ||
          data.data[0].markets[0].runners[0].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[0].backOdds / 100).toFixed(2);
        team_2_yes =
          data.data[0].markets[0].runners[1].backOdds > 99 ||
          data.data[0].markets[0].runners[1].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[1].backOdds / 100).toFixed(2);
        team_1_no =
          data.data[0].markets[0].runners[0].layOdds > 99 ||
          data.data[0].markets[0].runners[0].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[0].layOdds / 100).toFixed(2);
        team_2_no =
          data.data[0].markets[0].runners[1].layOdds > 99 ||
          data.data[0].markets[0].runners[1].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[1].layOdds / 100).toFixed(2);

        if (
          payload.teamA_back_rate == team_1_yes &&
          payload.teamA_lay_rate == team_1_no &&
          payload.teamB_back_rate == team_2_yes &&
          payload.teamB_lay_rate == team_2_no
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.");
        }
      }
    }
  };

  const checkConditions2 = (matchMin, matchMax, payload) => {
    if (payload.amount < matchMin) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`);
      return;
    }

    if (payload.amount > matchMax) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`);
      return;
    }

    let data = betGuruData;

    if (data.length !== 0) {
      if (
        payload.teamA_back_rate == betConverter(data.matchOdds[0].krate) &&
        payload.teamA_lay_rate == betConverter(data.matchOdds[0].lrate) &&
        payload.teamB_back_rate == betConverter(data.matchOdds[1].krate) &&
        payload.teamB_lay_rate == betConverter(data.matchOdds[1].lrate)
      ) {
        placeMatchBet(payload);
      } else {
        toast.error("Rate has changed.");
      }
    }
  };

  const checkConditions3 = (matchMin, matchMax, payload) => {
    if (payload.amount < matchMin) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`);
      return;
    }

    if (payload.amount > matchMax) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`);
      return;
    }

    let team_1_yes, team_2_yes, team_1_no, team_2_no;

    if (code === "0") {
      const data = match4;
      team_1_yes =
        data.market[0].events[0].BackPrice1 == "0.00" ||
        data.market[0].events[0].BackPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[0].BackPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[0].BackPrice1.split(".")[1]);

      team_2_yes =
        data.market[0].events[1].BackPrice1 == "0.00" ||
        data.market[0].events[1].BackPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[1].BackPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[1].BackPrice1.split(".")[1]);

      team_1_no =
        data.market[0].events[0].LayPrice1 == "0.00" ||
        data.market[0].events[0].LayPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[0].LayPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[0].LayPrice1.split(".")[1]);

      team_2_no =
        data.market[0].events[1].LayPrice1 == "0.00" ||
        data.market[0].events[1].LayPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[1].LayPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[1].LayPrice1.split(".")[1]);

      if (
        payload.teamA_back_rate == betConverter(team_1_yes) &&
        payload.teamA_lay_rate == betConverter(team_1_no) &&
        payload.teamB_back_rate == betConverter(team_2_yes) &&
        payload.teamB_lay_rate == betConverter(team_2_no)
      ) {
        placeMatchBet(payload);
      } else {
        toast.error("Rate has changed.");
      }
    } else {
      // let gameCode = code;

      let data = appleExchData;

      if (data.length !== 0) {
        team_1_yes =
          data.market[0].events[0].BackPrice1 == "0.00" ||
          data.market[0].events[0].BackPrice1 == "0.0"
            ? "0"
            : "0." +
              (data.market[0].events[0].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[0].BackPrice1.split(".")[1]);

        team_2_yes =
          data.market[0].events[1].BackPrice1 == "0.00" ||
          data.market[0].events[1].BackPrice1 == "0.0"
            ? "0"
            : "0." +
              (data.market[0].events[1].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[1].BackPrice1.split(".")[1]);

        team_1_no =
          data.market[0].events[0].LayPrice1 == "0.00" ||
          data.market[0].events[0].LayPrice1 == "0.0"
            ? "0"
            : "0." +
              (data.market[0].events[0].LayPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[0].LayPrice1.split(".")[1]);

        team_2_no =
          data.market[0].events[1].LayPrice1 == "0.00" ||
          data.market[0].events[1].LayPrice1 == "0.0"
            ? "0"
            : "0." +
              (data.market[0].events[1].LayPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[1].LayPrice1.split(".")[1]);

        if (
          payload.teamA_back_rate == betConverter(team_1_yes) &&
          payload.teamA_lay_rate == betConverter(team_1_no) &&
          payload.teamB_back_rate == betConverter(team_2_yes) &&
          payload.teamB_lay_rate == betConverter(team_2_no)
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.");
        }
      }
    }
  };

  const handlePlaceMatchBet = () => {
    const gameId = matchId;

    let matchMin = 0;
    let matchMax = 0;
    let betTimer = 0;
    let userLimit = 0;

    const walletBalancePayload = {
      acc_type: "user",
      user_id: userId,
    };

    getWalletBalance(walletBalancePayload, {
      onSuccess: (response) => {
        userLimit = Math.round(response.data.data.limit);

        if (userLimit < betAmount) {
          toast.error("Amount should be less then your wallet balance.");
          setMatchBetPayload({});
          setBetAllowed(false);
          setTimeLeft("");
          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }
          setPlaceBetLoader(false);
          return;
        } else {
          getMatchSetting(gameId, {
            onSuccess: (data) => {
              matchMin = data.data.data.match_min;
              matchMax = data.data.data.match_max;
              betTimer = data.data.data.bet_timer;

              const payload = {
                amount: betAmount,
                ...matchBetPayload,
              };

              if (api === "1") {
                if (betTimer === 0) {
                  checkConditions(matchMin, matchMax, payload);
                } else {
                  setTimeout(() => {
                    checkConditions(matchMin, matchMax, payload);
                  }, betTimer * 1000);
                }
              } else {
                if (betTimer === 0) {
                  checkConditions2(matchMin, matchMax, payload);
                } else {
                  setTimeout(() => {
                    checkConditions2(matchMin, matchMax, payload);
                  }, betTimer * 1000);
                }
              }
            },
            onError: (error) => {
              console.log("error", error);
            },
          });
        }
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  };

  const placeSessionBet = (payload) => {
    createSessionBet(payload, {
      onSuccess: (data) => {
        toast.success("Bet placed successfully!");
        setSessionBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }

        getTeamMatchBets();
        handleGetSessionTotal();
        handleGetSessionMatchBets();
        setPlaceBetLoader(false);
      },
      onError: (error) => {
        console.log("error", error);
        setSessionBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }
        setPlaceBetLoader(false);
        toast.error("Amount should be less then your wallet balance.");
      },
    });
  };

  const checkOperation = (matchMin, matchMax, payload) => {
    const marketId = payload.complete_session_id;

    if (code === "0") {
      if (matchDummy.data.length === 5) {
        const market = matchDummy.data[2].markets;
        const res = market.filter((el) => el.id == marketId);

        if (res.length === 1) {
          if (payload.match_condn) {
            if (!res[0].runners[0].rnrBallRunning) {
              if (
                payload.runs == Number(res[0].runners[0].backRuns) &&
                payload.rate == Number(res[0].runners[0].backOdds) / 100
              ) {
                placeSessionBet(payload);
              } else {
                toast.error("Rate has changed.");
              }
            } else {
              toast.error("Session Bet is locked.");
            }
          } else {
            if (!res[0].runners[0].rnrBallRunning) {
              if (
                payload.runs == Number(res[0].runners[0].layRuns) &&
                payload.rate == Number(res[0].runners[0].layOdds) / 100
              ) {
                placeSessionBet(payload);
              } else {
                toast.error("Rate has changed.");
              }
            } else {
              toast.error("Session Bet is locked.");
            }
          }
        }
      }
    } else {
      let res = fancyOddsData.filter((el) => el.id == marketId);

      if (res.length === 1) {
        if (payload.match_condn) {
          if (!res[0].runners[0].rnrBallRunning) {
            if (
              payload.runs == Number(res[0].runners[0].backRuns) &&
              payload.rate == Number(res[0].runners[0].backOdds) / 100
            ) {
              placeSessionBet(payload);
            } else {
              toast.error("Rate has changed.");
            }
          } else {
            toast.error("Session Bet is locked.");
          }
        } else {
          if (!res[0].runners[0].rnrBallRunning) {
            if (
              payload.runs == Number(res[0].runners[0].layRuns) &&
              payload.rate == Number(res[0].runners[0].layOdds) / 100
            ) {
              placeSessionBet(payload);
            } else {
              toast.error("Rate has changed.");
            }
          } else {
            toast.error("Session Bet is locked.");
          }
        }
      } else {
        toast.error("Incorrect Bet");
      }
    }
  };

  const checkOperation2 = (matchMin, matchMax, payload) => {
    const marketId = payload.complete_session_id;

    let res = fancyOddsData.filter((el) => el.id == marketId);

    if (res.length === 1) {
      if (payload.match_condn) {
        if (!res[0].runners[0].rnrBallRunning) {
          if (
            payload.runs == Number(res[0].runners[0].backRuns) &&
            payload.rate == Number(res[0].runners[0].backOdds) / 100
          ) {
            placeSessionBet(payload);
          } else {
            toast.error("Rate has changed.");
          }
        } else {
          toast.error("Session Bet is locked.");
        }
      } else {
        if (!res[0].runners[0].rnrBallRunning) {
          if (
            payload.runs == Number(res[0].runners[0].layRuns) &&
            payload.rate == Number(res[0].runners[0].layOdds) / 100
          ) {
            placeSessionBet(payload);
          } else {
            toast.error("Rate has changed.");
          }
        } else {
          toast.error("Session Bet is locked.");
        }
      }
    } else {
      toast.error("Incorrect Bet");
    }
  };

  const handlePlaceSessionBet = () => {
    const gameId = matchId;

    let matchMin = 0;
    let matchMax = 0;
    let betTimer = 0;
    let userLimit = 0;

    if (userId !== undefined) {
      const walletBalancePayload = {
        acc_type: "user",
        user_id: userId,
      };

      getWalletBalance(walletBalancePayload, {
        onSuccess: (response) => {
          userLimit = Math.round(response.data.data.limit);

          if (userLimit < betAmount) {
            toast.error("Amount should be less then your wallet balance.");
            setSessionBetPayload({});
            setBetAllowed(false);
            setTimeLeft("");
            const inputElement = document.querySelector("#MatchAmountInput");
            if (inputElement) {
              inputElement.value = "";
            }
            setPlaceBetLoader(false);
            return;
          } else {
            const payload = {
              amount: betAmount,
              ...sessionBetPayload,
            };

            getMatchSetting(gameId, {
              onSuccess: (data) => {
                matchMin = data.data.data.session_min;
                matchMax = 50000;
                betTimer = data.data.data.bet_timer;

                if (payload.amount < matchMin) {
                  setSessionBetPayload({});
                  toast.error(
                    `Incorrect Amount. Minimum bet amount is ${matchMin}`
                  );
                  return;
                }

                if (payload.amount > matchMax) {
                  setSessionBetPayload({});
                  toast.error(
                    `Incorrect Amount. Maximum bet amount is ${matchMax}`
                  );
                  return;
                }

                if (api === "1") {
                  if (betTimer === 0) {
                    checkOperation(matchMin, matchMax, payload);
                  } else {
                    setTimeout(() => {
                      checkOperation(matchMin, matchMax, payload);
                    }, betTimer * 1000);
                  }
                } else {
                  if (betTimer === 0) {
                    checkOperation2(matchMin, matchMax, payload);
                  } else {
                    setTimeout(() => {
                      checkOperation2(matchMin, matchMax, payload);
                    }, betTimer * 1000);
                  }
                }
              },
              onError: (error) => {
                console.log("error", error);
              },
            });
          }
        },
        onError: (error) => {
          toast.error(error);
        },
      });
    }
  };

  const handleMatchBetTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current); // Clear the previous timer if it exists
    }

    setTimeLeft("7");

    intervalIdRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 0) {
          clearInterval(intervalIdRef.current);
          setBetAllowed(false);
          setTimeLeft("");

          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }

          setPlaceBetLoader(false);

          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
  };

  const handleGetSessionTotal = () => {
    const sessionTotalPayload = {
      match_id: matchId,
      user_id: userId,
    };

    getSessionTotal(sessionTotalPayload, {
      onSuccess: (data) => {
        setSessionProfileLosses(data.data.data);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  useEffect(() => {
    getTeamMatchBets();

    handleGetSessionMatchBets();

    handleGetSessionTotal();

    if (tvcodeapi === undefined) {
      // setTvCodeUrl("https://sqltv.imax247.com/?eventId=" + tvcode);
      setTvCodeUrl("https://supertv.lotusbook9mm.com/tv2/?event_id=" + tvcode);
    } else {
      if (tvcodeapi === "2") {
        setTvCodeUrl("https://100tun.online/web/" + tvcode + ".html");
      } else {
        // setTvCodeUrl("https://sqltv.imax247.com/?eventId=" + tvcode);
        setTvCodeUrl(
          "https://supertv.lotusbook9mm.com/tv2/?event_id=" + tvcode
        );
      }
    }

    handleGetMatchSetting();

    const intervalId = setInterval(() => {
      handleGetMatchSetting();
    }, 6000);

    setMatchSetting(intervalId);

    const gameId = matchId;

    handleGetSessionSetting(gameId);

    const intervalId2 = setInterval(() => {
      handleGetSessionSetting(gameId);
    }, 6000);

    setMatchSessionSetting(intervalId2);

    if (score_code !== undefined || score_code !== "" || score_code !== null) {
      if (score_code.startsWith("1")) {
        setScoreBoardType("External");
      } else {
        setScoreBoardType("Betguru");
      }
    }

    if (api === "1") {
      if (code !== "0") {
        handleGetGameDetailsPaid(code);
      } else {
        getGameDetails(matchDummy.data);
      }
    } else {
      if (code !== "0") {
        handleGetAppleExch(code);
      } else {
        getGameDetails3(match4);
      }
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iceexchData) {
      setIceExchData(iceexchData.data);
      getGameDetails(iceexchData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iceexchData]);

  useEffect(() => {
    if (betguruData) {
      getGameDetails2(betguruData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betguruData]);

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <PageHeader1 sessionProfileLosses={sessionProfileLosses} />
          <div className="menu mb-1" id="menu" align="center">
            <ul className="nav">
              <li
                style={{ backgroundColor: "#52B5E1 !important" }}
                className="active abc w-100"
              >
                <a
                  className="active text-center"
                  style={{
                    backgroundColor: "#52B5E1 !important",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowText(!showText)}
                >
                  <FontAwesomeIcon icon={faTelevision} color="yellow" /> SHOW TV
                </a>
              </li>
              {showText && (
                <div className="w-100">
                  <iframe
                    src={tvCodeUrl}
                    title="Live Match"
                    class="match-tv"
                  ></iframe>
                </div>
              )}
            </ul>
            {matchComment !== "" ? (
              <div className="welcomeMarquee">
                <marquee>{matchComment}</marquee>
              </div>
            ) : null}
          </div>

          {scoreBoardType === "Betguru" ? (
            <iframe
              title="demo"
              width="100%"
              src={`https://score.zioplay.live/score.html?MatchID=${score_code}`}
              style={{ height: "160px" }}
            ></iframe>
          ) : (
            <iframe
              title="demo"
              width="100%"
              src={`https://winx777.com/score/?market_id=${score_code}`}
              style={{ height: "180px" }}
            ></iframe>
          )}

          {/* match Bhav */}
          <form name="BetPlayer">
            <div className="">
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td valign="top">
                      <table
                        width="100%"
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          {/* Bhav Bet code */}
                          <tr>
                            <td align="left" valign="top">
                              <table
                                width="100%"
                                border="0"
                                cellPadding="2"
                                cellSpacing="2"
                                className="table table-bordered align-middle mb-0 teamtable1"
                              >
                                <tbody>
                                  <tr bgcolor="#52b5e1">
                                    <td
                                      width="60%"
                                      className="FontTextWhite10px"
                                    >
                                      TEAM
                                      <br />
                                      <span>Min : 100 | Max : 100K</span>
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      LAGAI
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      KHAI
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody
                                  id="team-table-dst"
                                  bgcolor="#FFF"
                                  className="text-center"
                                >
                                  <tr>
                                    <td
                                      height="42"
                                      className="FontTextBlue"
                                      datatype="match"
                                    >
                                      {teamName1 === "" ? team1 : teamName1}
                                      <div
                                        className={`${
                                          team1Position > 0
                                            ? "text-primary"
                                            : "text-danger"
                                        } mt-1`}
                                      >
                                        {Math.round(team1Position)}
                                      </div>
                                    </td>
                                    <td
                                      className="text-blue text-bold text-md"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        if (
                                          isGameLocked ||
                                          team1Yes === "0" ||
                                          team1Yes === "" ||
                                          team1Yes === 0
                                        ) {
                                          alert("No Data!");
                                          return;
                                        }

                                        e.preventDefault();
                                        setMatchBetPayload({});
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");
                                        handleScroll(e);
                                        const payload = {
                                          type: "back",
                                          betTeam: "A",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                        };
                                        setMatchBetPayload(payload);
                                      }}
                                    >
                                      {isGameLocked ||
                                      team1Yes === "0" ||
                                      team1Yes === "" ||
                                      team1Yes === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team1Yes)
                                      )}
                                    </td>
                                    <td
                                      className="text-danger text-bold text-md"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          isGameLocked ||
                                          team1No === "0" ||
                                          team1No === "" ||
                                          team1No === 0
                                        ) {
                                          alert("No Data!");
                                          return;
                                        }
                                        setMatchBetPayload({});
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");
                                        handleScroll(e);
                                        const payload = {
                                          type: "lay",
                                          betTeam: "A",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                        };
                                        setMatchBetPayload(payload);
                                      }}
                                    >
                                      {isGameLocked ||
                                      team1No === "0" ||
                                      team1No === "" ||
                                      team1No === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team1No)
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      height="42"
                                      className="FontTextBlue"
                                      datatype="match"
                                    >
                                      {teamName2 === "" ? team2 : teamName2}
                                      <div
                                        className={`${
                                          team2Position > 0
                                            ? "text-primary"
                                            : "text-danger"
                                        } mt-1`}
                                      >
                                        {Math.round(team2Position)}
                                      </div>
                                    </td>
                                    <td
                                      className="text-blue text-bold text-md"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          isGameLocked ||
                                          team2Yes === "0" ||
                                          team2Yes === "" ||
                                          team2Yes === 0
                                        ) {
                                          alert("No Data!");
                                          return;
                                        }
                                        setMatchBetPayload({});
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");
                                        handleScroll(e);
                                        const payload = {
                                          type: "back",
                                          betTeam: "B",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                        };
                                        setMatchBetPayload(payload);
                                      }}
                                    >
                                      {isGameLocked ||
                                      team2Yes === "0" ||
                                      team2Yes === "" ||
                                      team2Yes === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team2Yes)
                                      )}
                                    </td>
                                    <td
                                      className="text-danger text-bold text-md"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          isGameLocked ||
                                          team2No === "0" ||
                                          team2No === "" ||
                                          team2No === 0
                                        ) {
                                          alert("No Data!");
                                          return;
                                        }
                                        setMatchBetPayload({});
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");
                                        handleScroll(e);
                                        const payload = {
                                          type: "lay",
                                          betTeam: "B",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                        };
                                        setMatchBetPayload(payload);
                                      }}
                                    >
                                      {isGameLocked ||
                                      team2No === "0" ||
                                      team2No === "" ||
                                      team2No === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team2No)
                                      )}
                                    </td>
                                  </tr>
                                  {match_type === "Test" && (
                                    <tr>
                                      <td
                                        height="42"
                                        className="FontTextBlue"
                                        datatype="match"
                                      >
                                        {team3}
                                        <div
                                          className={`${
                                            team3Position > 0
                                              ? "text-primary"
                                              : "text-danger"
                                          } mt-1`}
                                        >
                                          {team3Position}
                                        </div>
                                      </td>
                                      <td
                                        className="text-blue text-bold text-md"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleScroll(e);
                                        }}
                                      >
                                        {isGameLocked
                                          ? "0.00"
                                          : betConverter(team3Yes)}
                                      </td>
                                      <td
                                        className="text-danger text-bold text-md"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleScroll(e);
                                        }}
                                      >
                                        {isGameLocked
                                          ? "0.00"
                                          : betConverter(team3No)}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          {/* session bet */}
                          <tr>
                            <td align="left" valign="top">
                              <table
                                width="100%"
                                border="0"
                                cellPadding="2"
                                cellSpacing="2"
                                className="table table-bordered align-middle mb-0"
                              >
                                <tbody>
                                  <tr bgcolor="#52b5e1">
                                    <td
                                      width="60%"
                                      height="44"
                                      className="FontTextWhite10px"
                                    >
                                      SESSION
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      NO
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      YES
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody
                                  id="session-table-dst"
                                  bgcolor="#FFF"
                                  className="text-center align-middle"
                                >
                                  {fancyOddsData?.length !== 0 ? (
                                    fancyOddsData.map((item, index) => (
                                      <tr
                                        key={index}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        <td height="56">
                                          {item.marketType}
                                          <br />
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                              fontSize: "0.75rem",
                                            }}
                                          >
                                            Min : 100 | Max : 50K
                                          </span>
                                        </td>

                                        <td
                                          className="text-danger text-bold text-md"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            if (
                                              item.runners[0].rnrBallRunning ||
                                              isGameLocked
                                            ) {
                                              alert("No Data!");
                                              return;
                                            }

                                            e.preventDefault();
                                            setSessionBetPayload({});
                                            setBetAllowed(true);
                                            handleMatchBetTimer();
                                            setBetType("sessionBet");
                                            handleScroll(e);
                                            const payload = {
                                              unique:
                                                Number(
                                                  item.runners[0].backRuns
                                                ) >
                                                Number(item.runners[0].layRuns)
                                                  ? true
                                                  : false,
                                              user_id: userId,
                                              session: item.marketType,
                                              match_id: matchId,
                                              mode: "N",
                                              rate: Number(
                                                item.runners[0].layOdds / 100
                                              ),
                                              runs: Number(
                                                item.runners[0].layRuns
                                              ),
                                              match_condn: false,
                                              mob_commision: 0.0,
                                              code: code,
                                              match_type: match_type,
                                              api: api,
                                              complete_session_id:
                                                api === "1"
                                                  ? item.id
                                                  : item.runners[0].id,
                                            };
                                            setSessionBetPayload(payload);
                                          }}
                                        >
                                          {item.runners[0].rnrBallRunning ||
                                          isGameLocked ? (
                                            <>
                                              <div className="pb-2">0</div>
                                              0.0
                                            </>
                                          ) : (
                                            <>
                                              <div className="pb-2">
                                                {item.runners[0].layRuns}
                                              </div>
                                              {(
                                                item.runners[0].layOdds / 100
                                              ).toFixed(2)}
                                            </>
                                          )}
                                        </td>
                                        <td
                                          className="text-primary text-bold text-md"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            if (
                                              item.runners[0].rnrBallRunning ||
                                              isGameLocked
                                            ) {
                                              alert("No Data!");
                                              return;
                                            }

                                            e.preventDefault();
                                            setSessionBetPayload({});
                                            setBetAllowed(true);
                                            handleMatchBetTimer();
                                            setBetType("sessionBet");
                                            handleScroll(e);
                                            const payload = {
                                              unique:
                                                Number(
                                                  item.runners[0].backRuns
                                                ) >
                                                Number(item.runners[0].layRuns)
                                                  ? true
                                                  : false,
                                              user_id: userId,
                                              session: item.marketType,
                                              match_id: matchId,
                                              mode: "Y",
                                              rate: Number(
                                                item.runners[0].backOdds / 100
                                              ),
                                              runs: Number(
                                                item.runners[0].backRuns
                                              ),
                                              match_condn: true,
                                              mob_commision: 0.0,
                                              code: code,
                                              match_type: match_type,
                                              api: api,
                                              complete_session_id:
                                                api === "1"
                                                  ? item.id
                                                  : item.runners[0].id,
                                            };
                                            setSessionBetPayload(payload);
                                          }}
                                        >
                                          {item.runners[0].rnrBallRunning ||
                                          isGameLocked ? (
                                            <>
                                              <div className="pb-2">0</div>
                                              0.0
                                            </>
                                          ) : (
                                            <>
                                              <div className="pb-2">
                                                {item.runners[0].backRuns}
                                              </div>
                                              {(
                                                item.runners[0].backOdds / 100
                                              ).toFixed(2)}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        className="text-center p-3 text-md text-bold bg-white"
                                      >
                                        No Session Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td align="left" valign="top">
                              <table
                                width="100%"
                                border="0"
                                cellPadding="0"
                                cellSpacing="2"
                                id="PlaceBet"
                                className="table table-bordered align-middle mb-0"
                              >
                                <tbody>
                                  <tr bgcolor="#00FFFF">
                                    <td
                                      height="42"
                                      align="center"
                                      valign="middle"
                                      bgcolor="#00FFFF"
                                      className="FontTextBlue"
                                    >
                                      AMOUNT
                                    </td>
                                    <td
                                      align="center"
                                      valign="middle"
                                      bgcolor="#00FFFF"
                                    >
                                      <input
                                        type="number"
                                        name="MatchAmount"
                                        list="AmountList"
                                        className="MatchAmount"
                                        id="MatchAmountInput"
                                        size="10"
                                        maxLength="7"
                                        disabled={!betAllowed}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setBetAmount(e.target.value);
                                        }}
                                        min="0"
                                        autoComplete="OFF"
                                        placeholder="Enter Amount"
                                      />
                                      <datalist id="AmountList">
                                        <option value="500"></option>
                                        <option value="1000"></option>
                                        <option value="1500"></option>
                                        <option value="2000"></option>
                                        <option value="2500"></option>
                                        <option value="3000"></option>
                                        <option value="3500"></option>
                                        <option value="4000"></option>
                                        <option value="4500"></option>
                                        <option value="5000"></option>
                                        <option value="10000"></option>
                                        <option value="15000"></option>
                                        <option value="20000"></option>
                                        <option value="25000"></option>
                                        <option value="30000"></option>
                                        <option value="35000"></option>
                                        <option value="40000"></option>
                                        <option value="45000"></option>
                                        <option value="50000"></option>
                                        <option value="100000"></option>
                                        <option value="150000"></option>
                                        <option value="200000"></option>
                                        <option value="250000"></option>
                                        <option value="300000"></option>
                                        <option value="350000"></option>
                                        <option value="400000"></option>
                                        <option value="450000"></option>
                                        <option value="500000"></option>
                                      </datalist>
                                    </td>

                                    <td
                                      align="center"
                                      valign="middle"
                                      bgcolor="#00FFFF"
                                    >
                                      <input
                                        name="AmountTime"
                                        type="text"
                                        list="AmountList"
                                        className="AmountTime p-1 rounded-1"
                                        id="AmountTime"
                                        size="4"
                                        value={timeLeft}
                                        // value={7}
                                        maxLength="3"
                                        readOnly
                                        disabled
                                        autoComplete="OFF"
                                      />
                                    </td>
                                    <td
                                      align="center"
                                      valign="middle"
                                      bgcolor="#00FFFF"
                                    >
                                      <input
                                        name="cmdDone"
                                        style={{
                                          color: "black",
                                          border: "none",
                                          fontWeight: "bold",
                                          fontSize: "15px",
                                        }}
                                        type="button"
                                        id="cmdDone"
                                        value="Done"
                                        className="p-2 rounded-1"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (!placeBetLoader) {
                                            setPlaceBetLoader(true);
                                            if (betType === "matchBet") {
                                              handlePlaceMatchBet();
                                            } else {
                                              handlePlaceSessionBet();
                                            }
                                          }
                                        }}
                                        disabled={placeBetLoader}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

          {/* Match Bets */}
          <br />
          <div className="table-responsive">
            <table
              width="100%"
              border="0"
              cellPadding="2"
              cellSpacing="2"
              id="MyBets"
              className="table table-bordered align-middle"
            >
              <tbody>
                <tr bgcolor="#52b5e1">
                  <td
                    height="35"
                    className="FontTextWhite10px"
                    style={{ width: "10px", borderWidth: "1px" }}
                  >
                    Sr.
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Rate
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Amount
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Mode
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Team
                  </td>
                </tr>
              </tbody>

              <tbody bgcolor="#FFF" className="text-center">
                {teamBets?.length !== 0 ? (
                  teamBets.map((item, index) => (
                    <tr key={index}>
                      <td height="32">{index + 1}</td>
                      <td>{item.rate}</td>
                      <td>{item.amount}</td>
                      <td>{item.mode}</td>
                      <td>{item.team}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center p-2 text-md text-bold bg-white"
                    >
                      No Bet Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Session Bets */}
          <div>
            <table
              width="100%"
              border="0"
              cellPadding="2"
              cellSpacing="2"
              id="MyBets"
              className="table table-bordered align-middle"
            >
              <tbody>
                <tr bgcolor="#52b5e1">
                  <td
                    height="35"
                    className="FontTextWhite10px sesstiontablerate"
                    id="tablelastcol1"
                    style={{ fontSize: "10px " }}
                  >
                    Sr.
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Session
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Rate
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Amount
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Run
                  </td>
                  <td
                    className="FontTextWhite10px p1"
                    style={{ fontSize: "10px " }}
                  >
                    Mode
                  </td>
                  <td
                    className="FontTextWhite10px p2"
                    style={{ fontSize: "10px " }}
                  >
                    Dec
                  </td>
                </tr>
              </tbody>

              <tbody bgcolor="#FFF" className="text-center">
                {sessionBets?.length !== 0 ? (
                  sessionBets.map((item, index) => (
                    <tr key={index}>
                      <td height="32">{index + 1}</td>
                      <td>{item.session}</td>
                      <td>{item.rate.toFixed(2)}</td>
                      <td>{item.amount}</td>
                      <td>{item.runs}</td>
                      <td>{item.mode}</td>
                      <td>{item.declare}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="text-center p-2 text-md text-bold bg-white"
                    >
                      No Bet Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="menu" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/user-inplay" className="active upcoming">
                  BACK TO UPCOMING GAMES
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
