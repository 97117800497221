import React, { useState, useEffect } from "react";
import "./UserInplay.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import useGetLiveMatch from "./hooks/useGetLiveMatch";
import { dateTimeFormatter } from "../utils/dateFormatter";

export default function UserInplay() {
  const navigate = useNavigate();
  let userId = sessionStorage.getItem("UUID");
  let userData = JSON.parse(sessionStorage.getItem("DATA")).data;

  const { mutate: getLiveMatch } = useGetLiveMatch();

  const [liveMatches, setLiveMatches] = useState([]);

  useEffect(() => {
    if (userData) {
      const payload = {
        user_id: userId,
      };

      getLiveMatch(payload, {
        onSuccess: (response) => {
          setLiveMatches(response.data.data);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startBet = (item) => {
    // console.log(item);
    sessionStorage.setItem("match_id", item.id);
    sessionStorage.setItem("game_name", item.name);
    sessionStorage.setItem("team1", item.team1);
    sessionStorage.setItem("team2", item.team2);
    sessionStorage.setItem("type", item.server);
    sessionStorage.setItem("match_type", item.match_type);
    sessionStorage.setItem(
      "session_automatic",
      item.session_automatic ? "true" : "false"
    );
    sessionStorage.setItem("score_code", item.score_code);
    sessionStorage.setItem("CALLED", item.api);
    sessionStorage.setItem("tvcode", item.tv_code);
    sessionStorage.setItem("code_", item.code);
    sessionStorage.setItem("tvcodeapi", item.tv_code_api);

    navigate(`/user-inplay/start-bet?id=${item.id}`);
  };

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <PageHeader title="IN PLAY" />
          <div className="menu mt-2" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>
          <br />
          <div className="row px-0 pt-0">
            {liveMatches.length > 0 ? (
              liveMatches?.map((item, index) => (
                <div className="inplylist mb-3" key={`inplay-${index}`}>
                  <Link
                    // to={"/user-start-bet"}
                    // state={item}
                    onClick={(e) => {
                      e.preventDefault();
                      startBet(item);
                    }}
                    className="inplylistLink"
                  >
                    <div className="teamName">
                      {item.team1} VS {item.team2} ({item.match_type})
                    </div>
                    <div
                      className="row d-flex flex-column justify-content-center align-items-center py-2 text-black gap-2 text-center shadow-sm"
                      style={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      <div>{dateTimeFormatter(item.server)}</div>
                      <div>Match Bets : {item.total_match_bets.length}</div>
                      <div>Session Bets : {item.total_session_bets.length}</div>
                      <div>Declared : No</div>
                      <div>Won by : </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="inplylist mb-3">
                <div className="teamName shadow-sm">No live matches found</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
