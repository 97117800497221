import React from "react";
import { Link } from "react-router-dom";
// import { useEffect } from "react";
import PageHeader from "./Components/PageHeader";
import Footer from "./Components/Footer";
import crickImage from "../img/crick.png";
import casinoImage from "../img/casino.png";
import CG1 from "../img/CG1.jpg";
import Profileimage from "../img/Profile.png";
import CLimage from "../img/CL.png";
import CPimage from "../img/CP.png";

export default function MainMenu() {
  return (
    <>
      <PageHeader />
      <div className="content_top">
        <div className="content_top-grid3"></div>
        <div className="clear"> </div>
      </div>
      <table
        width="100%"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{ backgroundColor: "#FFF" }}
      >
        <tbody>
          <tr>
            <td style={{ marginBottom: "100px" }}>
              <table width="100%" border="0" cellSpacing="0" cellPadding="10">
                <tbody>
                  <tr>
                    <td align="center">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <Link to="/user-inplay">
                        <img src={crickImage} alt="" width="112" height="112" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td height="25" align="center" className="GameList">
                      <Link to="/user-inplay" style={{ color: "#000000" }}>
                        In Play
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <Link to="/user-casino">
                        <img
                          src={casinoImage}
                          alt=""
                          width="112"
                          height="112"
                        />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td height="25" align="center" className="GameList">
                      <Link to="/user-casino" style={{ color: "#000000" }}>
                        Casino
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>

          <tr>
            <td>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center">
                      <Link to="/user-completegame-list">
                        <img src={CG1} alt="" width="112" height="112" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td height="25" align="center" className="GameList">
                      <Link
                        to="/user-completegame-list"
                        style={{ color: "#000000" }}
                      >
                        Complete Games
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center">
                      <Link to="/user-profile">
                        <img
                          src={Profileimage}
                          alt=""
                          width="112"
                          height="112"
                        />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td height="25" align="center" className="GameList">
                      <Link to="/user-profile" style={{ color: "#000000" }}>
                        My Profile
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>

          <tr>
            <td>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center">
                      <Link to="/user-ledger">
                        <img src={CLimage} alt="" width="112" height="112" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td height="25" align="center" className="GameList">
                      <Link to="/user-ledger" style={{ color: "#000000" }}>
                        My Ledger
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center">
                      <Link to="/user-password">
                        <img src={CPimage} alt="" width="112" height="112" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td height="25" align="center" className="GameList">
                      <Link to="/user-password" style={{ color: "#000000" }}>
                        Change Password
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr></tr>
                  <tr></tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>

      <Footer />
    </>
  );
}
